import React from "react";
import whattheysay from "../../Assets/Home/Whattheysay/Picture.svg";
import { styled } from "styled-components";
import Slider from "react-slick";
import "./whattheysay.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import isMobile from "../../Assets/isMobile";

function Whattheysay() {
  var settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: isMobile() ? false : true,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
  };
  const Container = styled.div`
    padding: 5% 5% 5% 5%;
    margin-bottom: 20px;
  `;
  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0.03em;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.03em;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.03em;
      text-align: left;
    }
  `;

  const Containers = styled.div`
    // display: flex;
  `;

  const ContainerBox = styled.div`
    padding: 2.5% !important;
    margin: 10% !important;
    width: 560px !important;
    // height: 284px !important;
    box-shadow: 0px 4px 12px 0px #0000001a !important;

    @media (max-width: 768px) {
      margin: 0% !important;
      width: 400px !important;
      height: 330px !important;
      border-radius: 5px !important;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 559px !important;
      height: 310px !important;
    }
  `;

  const ContainerBoxHeading = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.5px;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0.5px;
      text-align: left;
    }
  `;

  const ContainerBoxs = styled.div`
    display: flex;
    position: relative;
  `;

  const ContainerBoxs1 = styled.div`
    width: 146px;
    position: absolute;
    top: 20px;
  `;
  const ContainerBoxs1R = styled.div`
    width: 50%;
    // position: absolute;
    margin-left: 165px;
    top: 83px;
  `;

  const ContainerBoxs1Images = styled.img`
    width: 105px;
    height: 120px;
    border-radius: 10px;
  `;

  const ContainerBox1Heading = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: left;
      margin-top: 20px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: left;
    }
  `;

  const ContainerBox1SubHeading = styled.div`
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.5px;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.5px;
      text-align: left;
    }
  `;

  return (
    <Container>
      <ContainerHeading>What they say about us</ContainerHeading>
      <Containers>
        <Slider {...settings}>
          <ContainerBox>
            <ContainerBoxHeading>
              "PDPL has been our trusted partner for years, consistently
              providing quality products. Their wide selection of healthcare
              products meets diverse patient needs. Their efficient distribution
              network and prompt service exceed expectations. Highly recommended
              pharmaceutical distributor."
            </ContainerBoxHeading>
            <ContainerBoxs>
              <ContainerBoxs1>
                <ContainerBoxs1Images src={whattheysay} />
              </ContainerBoxs1>
              <ContainerBoxs1R>
                <ContainerBox1Heading>Brendan Buchholz</ContainerBox1Heading>
                <ContainerBox1SubHeading>
                  Director, Appolo Hospital
                </ContainerBox1SubHeading>
                <ContainerBox1SubHeading>Delhi</ContainerBox1SubHeading>
              </ContainerBoxs1R>
            </ContainerBoxs>
          </ContainerBox>
          <ContainerBox>
            <ContainerBoxHeading>
              “PDPL’s top-notch pharmaceutical distribution services ensure
              efficiency and safety. Their wide range of genuine healthcare
              products guarantees quality. The experienced team ensures timely
              delivery. Professionalism and dedication to customer satisfaction
              make them a reliable choice. Highly recommended distributor."
            </ContainerBoxHeading>
            <ContainerBoxs>
              <ContainerBoxs1>
                <ContainerBoxs1Images src={whattheysay} />
              </ContainerBoxs1>
              <ContainerBoxs1R>
                <ContainerBox1Heading>Brendan Buchholz</ContainerBox1Heading>
                <ContainerBox1SubHeading>
                  Director, Appolo Hospital
                </ContainerBox1SubHeading>
                <ContainerBox1SubHeading>Delhi</ContainerBox1SubHeading>
              </ContainerBoxs1R>
            </ContainerBoxs>
          </ContainerBox>
          <ContainerBox>
            <ContainerBoxHeading>
              "PDPL has been our trusted partner for years, consistently
              providing quality products. Their wide selection of healthcare
              products meets diverse patient needs. Their efficient distribution
              network and prompt service exceed expectations. Highly recommended
              pharmaceutical distributor."
            </ContainerBoxHeading>
            <ContainerBoxs>
              <ContainerBoxs1>
                <ContainerBoxs1Images src={whattheysay} />
              </ContainerBoxs1>
              <ContainerBoxs1R>
                <ContainerBox1Heading>Brendan Buchholz</ContainerBox1Heading>
                <ContainerBox1SubHeading>
                  Director, Appolo Hospital
                </ContainerBox1SubHeading>
                <ContainerBox1SubHeading>Delhi</ContainerBox1SubHeading>
              </ContainerBoxs1R>
            </ContainerBoxs>
          </ContainerBox>
        </Slider>
      </Containers>
    </Container>
  );
}

export default Whattheysay;
