import { Grid } from "@mui/material";
import React from "react";
import cover2 from "../../Assets/Careers/Cover/Frame2.png";

import cover3 from "../../Assets/Careers/Cover/Frame (2).svg";

import cover4 from "../../Assets/Careers/Cover/Frame (6).svg";

import cover5 from "../../Assets/Careers/Cover/Frame1.png";

import cover6 from "../../Assets/Careers/Cover/Frame3.png";
import { styled } from "styled-components";
import bac from "../../Assets/Careers/Cover/image 226.png";

function Cover() {
  const Container = styled.div`
    padding: 5% 5%;
    background-image: url("${bac}");
    background-color: #f5f5f5;
  `;

  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 44px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
    }
  `;
  const ContainerBoxImage = styled.img`
    width: 130px;
    height: 130px;
    @media (max-width: 768px) {
      width: 52px;
      height: 52px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 96px;
      height: 96px;
    }
  `;
  const ContainerBoxHeading = styled.div`
    font-family: Playfair Display;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
    }
  `;
  const ContainerBOxSubHeading = styled.div`
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
    }
  `;

  return (
    <Container>
      <ContainerHeading>We've Got You Covered</ContainerHeading>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            alignItems: "center",
            textAlign: "center",
            padding: "2% 5% 0% 5%",
            gap: "11px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerBoxImage src={cover5} />
          <ContainerBoxHeading>Healthcare:</ContainerBoxHeading>
          <ContainerBOxSubHeading>
            We prioritize employees' well-being, offering comprehensive medical,
            dental, and vision coverage for you and your family to thrive.
          </ContainerBOxSubHeading>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            alignItems: "center",
            textAlign: "center",
            padding: "2% 5% 0% 5%",

            gap: "11px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerBoxImage src={cover2} />
          <ContainerBoxHeading>Family Care:</ContainerBoxHeading>
          <ContainerBOxSubHeading>
            At PDPL, we value work-life balance and family. Our family-friendly
            policies & benefits support personal commitments, ensuring fair
            work-life balance for all team members.
          </ContainerBOxSubHeading>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            alignItems: "center",
            textAlign: "center",
            padding: "2% 5% 0% 5%",

            gap: "11px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerBoxImage src={cover3} />
          <ContainerBoxHeading>Paid Time Off:</ContainerBoxHeading>
          <ContainerBOxSubHeading>
            In healthcare, we prioritize well-being. Our team enjoys generous
            paid time off to recharge and enjoy life.
          </ContainerBOxSubHeading>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            alignItems: "center",
            textAlign: "center",
            padding: "2% 5% 0% 5%",

            gap: "11px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerBoxImage src={cover5} />
          <ContainerBoxHeading>Community:</ContainerBoxHeading>
          <ContainerBOxSubHeading>
            Community engagement matters to us. We encourage our team members to
            participate in initiatives and create positive impact. Together, we
            make meaningful change.
          </ContainerBOxSubHeading>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            alignItems: "center",
            textAlign: "center",
            padding: "2% 5% 0% 5%",

            gap: "11px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerBoxImage src={cover4} />
          <ContainerBoxHeading>Growth:</ContainerBoxHeading>
          <ContainerBOxSubHeading>
            PDPL team offers training, mentorship, career paths to unleash your
            potential. We invest in employees for success.
          </ContainerBOxSubHeading>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            alignItems: "center",
            textAlign: "center",
            padding: "2% 5% 0% 5%",

            gap: "11px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContainerBoxImage src={cover6} />
          <ContainerBoxHeading>Convenience:</ContainerBoxHeading>
          <ContainerBOxSubHeading>
            Work flexibly, in-office or remote. Find your balance.
          </ContainerBOxSubHeading>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Cover;
