import { Grid } from "@mui/material";
import React from "react";
import whatwedo from "../../Assets/Home/Whatwedo/Group 29122.svg";
import { styled } from "styled-components";

function Whatwedo() {
  const Container = styled.div`
    padding: 5% 5%;
  `;
  const Containers = styled.div``;
  const ContainerImage = styled.img`
    width: 100%;
    height: 100%;
  `;
  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0.03em;
    text-align: left;
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.03em;
      text-align: left;
    }
    @media (max-width: 480px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.03em;
      text-align: center;
    }
  `;
  const ContianerSubHeading = styled.div`
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    // line-height: 52px;
    letter-spacing: 0.15007999539375305px;
    text-align: left;
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.15007999539375305px;
      text-align: left;
    }
    @media (max-width: 480px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
  `;
  return (
    <Container>
      <Grid
        container
        sx={{ flexDirection: { xs: "column-reverse", sm: "row", lg: "row" } }}
      >
        <Grid item xs={12} sm={5} lg={5}>
          <ContainerImage src={whatwedo} />
        </Grid>
        <Grid
          xs={12}
          sm={7}
          lg={7}
          style={{ paddingLeft: "5%", marginTop: "auto", marginBottom: "auto" }}
        >
          <ContainerHeading>What we do</ContainerHeading>
          <ContianerSubHeading>
            We are a trusted pharmaceutical distribution provider in India,
            delivering genuine healthcare products, medicines, drugs,
            consumables, implants, masks, PPE kits, and more. Our experienced
            team leverages digital platforms, and ensures effective delivery
            through proper shipping channels. With a commitment to excellence
            and end-to-end technological solutions, we have become a leading
            name in pharmaceutical distribution.
          </ContianerSubHeading>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Whatwedo;
