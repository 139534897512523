import React from "react";
import Join from "./Careers/Join";
import Cover from "./Careers/Cover";
import Lifeatpdpl from "./Careers/Lifeatpdpl";
import Roles from "./Careers/Roles";

function Career() {
  return (
    <div>
      <Join />
      <Cover />
      <Lifeatpdpl />
      <Roles />
    </div>
  );
}

export default Career;
