import React from 'react';
import Enquiries from './Enquiries1';
import EnquiriesMobile from './EnquiriesMobile';
import isMobile from '../../Assets/isMobile';

function Contactus() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <div>{!isMobile() ? <Enquiries /> : <EnquiriesMobile />}</div>;
}

export default Contactus;
