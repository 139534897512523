import { ClickAwayListener, Paper, Popper } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router";
import submenu from "../../Assets/logo.svg";
import submenu1 from "../../Assets/uim_multiply.svg";

function Submenu() {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const ButtonsStyles = styled.button`
    // width: 113px;
    height: 33px;
    padding: 6px 17px 6px 17px;
    border-radius: 3px;
    box-shadow: 0px 4px 12px 0px #0000001a;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    text-align: center;
    background-color: #1d79bb;
    text-align: center;
    color: #ffffff;
    border: 0;
    cursor: pointer;
    // margin-left: 25%;
    // margin-right: auto;
  `;

  const MenuItems = styled.div`
    margin: 10% 0%;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #1d79bb;
    }
  `;

  const handleClickAway = () => {
    setOpen(false);
  };

  const Container = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #898989;
  `;
  const ContainerImage = styled.img`
    margin-right: auto;
    margin-left: 20px;
  `;
  const ContainerButton = styled.div`
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  `;
  const ContainerButtonImage = styled.img``;

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <span>
          <MenuIcon onClick={handleClick} />
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            sx={{
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                border: 1,
                p: 1,
                bgcolor: "background.paper",
                marginTop: "11%",
                width: "264px",
                // height: "264px",
                border: "1px",
              }}
            >
              <Container>
                <ContainerImage src={submenu} />
                <ContainerButton onClick={() => handleClickAway()}>
                  <ContainerButtonImage src={submenu1} />
                </ContainerButton>
              </Container>
              <MenuItems
                value="Home"
                onClick={() => {
                  navigate("Home");
                  setOpen(false);
                }}
              >
                Home
              </MenuItems>
              <MenuItems
                value="aboutus"
                onClick={() => {
                  navigate("aboutus");
                  setOpen(false);
                }}
              >
                About us
              </MenuItems>
              <MenuItems
                value="aboutus"
                onClick={() => {
                  navigate("partners");
                  setOpen(false);
                }}
              >
                Partners
              </MenuItems>
              <MenuItems
                value="aboutus"
                onClick={() => {
                  navigate("career");
                  setOpen(false);
                }}
              >
                Career
              </MenuItems>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ButtonsStyles
                  onClick={() => {
                    navigate("/contactus");
                  }}
                >
                  Contact us
                </ButtonsStyles>
              </div>
            </Box>
          </Popper>
        </span>
      </ClickAwayListener>
    </>
  );
}

export default Submenu;
