import React from 'react';
import OurPartner from './OurPartners';
import PartnersLists from './PartnersLists';
import Sparsh from './Sparsh';
import OurPartnerMobile from './OurPartnersMobile';
import SparshMobile from './SparshMobile';
import isMobile from '../../Assets/isMobile';

function Partners() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {isMobile() ? <OurPartnerMobile /> : <OurPartner />}
      <PartnersLists />
      {isMobile() ? <SparshMobile /> : <Sparsh />}
    </div>
  );
}

export default Partners;
