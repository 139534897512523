import React from "react";
import join from "../../Assets/Careers/Join/4866233 1.svg";
import { styled } from "styled-components";
import { Grid } from "@mui/material";
import isMobile from "../../Assets/isMobile";

function Join() {
  const Container = styled.div`
    padding: 5% 5%;
  `;
  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 64px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 22px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.5px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 48px;
      font-weight: 700;
      line-height: 78px;
      letter-spacing: 0.5px;
      text-align: center;
    }
  `;

  const ContainerSubheading = styled.div`
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.5px;
    text-align: left;

    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.5px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.5px;
      text-align: center;
    }
  `;

  const ContainerImage = styled.img`
    width: 100%;
    height: 100%;
  `;
  const ContainerButton = styled.button`
    width: 251.9664306640625px;
    height: 62.783878326416016px;
    border-radius: 5px;
    box-shadow: 0px 8px 24px 0px #00000040;
    background-color: #1d79bb;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-top: 20px;
    margin-right: auto;
    border: 0;
    @media (max-width: 768px) {
      width: 142px;
      height: 33px;
      padding: 0px;
      border-radius: 3px;
      background-color: #ffffff;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #1d79bb;
      margin-right: auto;
      margin-left: auto;
      display: none;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: fit-content;
      height: 49px;
      padding: 14px 19px 14px 19px;
      border-radius: 5px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #1d79bb;
      background-color: #ffffff;
      margin-right: auto;
      margin-left: auto;
      display: none;
    }
  `;
  const ContainerButtons = styled.button`
    width: 251.9664306640625px;
    height: 62.783878326416016px;
    border-radius: 5px;
    box-shadow: 0px 8px 24px 0px #00000040;
    background-color: #1d79bb;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-top: 20px;
    margin-right: auto;
    display: none;
    border: 0;
    @media (max-width: 768px) {
      width: 142px;
      height: 33px;
      padding: 0px;
      border-radius: 3px;
      background-color: #ffffff;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #1d79bb;
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: fit-content;
      height: 49px;
      padding: 14px 19px 14px 19px;
      border-radius: 5px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: left;
      color: #1d79bb;
      background-color: #ffffff;
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
  `;

  return (
    <Container>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6.5}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "auto",
            marginBottom: "auto",
            paddingRight: isMobile() ? "" : "46px",
          }}
        >
          <ContainerHeading>
            A Thriving Career Awaits You at PDPL!
          </ContainerHeading>
          <ContainerSubheading>
            PDPL has some exciting career opportunities for you. Join a dynamic
            team dedicated to innovation, growth, and making a positive impact.
          </ContainerSubheading>
          <ContainerButton style={{ display: { sm: "block", lg: "none" } }}>
            See Open Roles
          </ContainerButton>
        </Grid>
        <Grid item xs={12} sm={12} lg={5.5}>
          <ContainerImage src={join} />
        </Grid>
        <ContainerButtons style={{ display: { sm: "block", lg: "none" } }}>
          See Open Roles
        </ContainerButtons>
      </Grid>
    </Container>
  );
}

export default Join;
