import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Felix from "../Assets/images/Compamieslogo/Felix.png";
import Fortis from "../Assets/images/Compamieslogo/Fortis.png";
import Jaipur from "../Assets/images/Compamieslogo/JaipurGolden.png";
import Jaypee from "../Assets/images/Compamieslogo/Jaypee.png";
import Max from "../Assets/images/Compamieslogo/Max.png";
import Park from "../Assets/images/Compamieslogo/Park.png";
import PremHospital from "../Assets/images/Compamieslogo/Prem-Hospital.png";
import Saroj from "../Assets/images/Compamieslogo/Saroj.png";
import Yatharth from "../Assets/images/Compamieslogo/Yatharth.png";

const slideImages = [
  Felix,
  Fortis,
  Jaipur,
  Jaypee,
  Park,
  PremHospital,
  Saroj,
  Yatharth,
];

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Slide slidesToScroll={1} slidesToShow={5} indicators={false}>
        {slideImages.map((slide, index) => (
          <div
            style={{
              width: "100%",
              paddingLeft: "6%",
            }}
            key={index}
          >
            <img src={slide} style={{ width: "100%" }} />
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
