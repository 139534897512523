import React from "react";
import Intro from "./Intro";
import Infos from "./Infos";
import PurposeValue from "./PurposeValue";
import Leaders from "./Leaders";
import Network from "./Network";
import Awards from "./Awards";

function Aboutus() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: "1.234%" }}>
      <Intro />
      <Infos />
      <PurposeValue />
      <Leaders />
      <Network />
      <Awards />
    </div>
  );
}

export default Aboutus;
