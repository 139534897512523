import { Button } from "@mui/material";
import styled from "styled-components";

export const MenuItems = styled(Button)`
  // width: 93px !important;
  height: 48px !important;
  text-transform: none !important;
  font-family: Helvetica !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: 0.45712000131607056px !important;
  text-align: center !important;
  color: #000 !important;
  &:hover {
    color: #1d79bb !important;
  }
  @media (min-width: 481px) and (max-width: 1300px) {
    font-family: Helvetica !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    letter-spacing: 0.45712000131607056px !important;
    text-align: center !important;
  }
  ${(props) => (props.active ? "color: #1d79bb !important" : "")}
`;

export const MenuItemsContact = styled(Button)`
  font-family: '"Times New Roman", Times, serif !important' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  text-align: center;
  text-transform: none !important;
  margin-left: 39px !important;
  background: linear-gradient(to right, blue 50%, white 50%) !important;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out !important;
  &:hover {
    background-position: left bottom;
  }
`;
