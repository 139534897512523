import React from "react";
import { styled } from "styled-components";
import banner from "../../Assets/Home/Banners/ezgif-1-661c4ee00c.gif";
import banner1 from "../../Assets/Home/Banners/ezgif-1-c15d0a8696.gif";
import banner2 from "../../Assets/Home/Banners/ezgif-1-df2501a479.gif";
import banner3 from "../../Assets/Home/Banners/ezgif-5-edfdfe5b80.gif";
import Slider from "react-slick";
import "./banner.css";
import { createUseStyles } from "react-jss";
import isMobile from "../../Assets/isMobile";
import { Button } from "@mui/material";
import { ArrowBackIosRounded } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";

function Banners() {
  const navigate = useNavigate();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <ArrowBackIosRounded />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  const useStyles = createUseStyles({
    sliderContainer: {
      "& .slick-list": {
        marginRight: "-1%",
        marginLeft: "-5%",
      },
    },
  });

  const classes = useStyles();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classes.sliderContainer,
  };
  const Container = styled.div`
    width: 1920px;
    // height: 766px;
    width: 100%;
    display: block;
  `;
  const Container1 = styled.div`
    width: 1920px;
    // height: 766px;
    width: 100%;
    background: linear-gradient(
      90.18deg,
      #1d79bb 0.13%,
      #1d79bb 24.19%,
      #1d79bb 44.58%,
      rgba(29, 121, 187, 0.97) 50.76%,
      rgba(29, 121, 187, 0) 99.82%
    );
    display: flex;
    background-image: url(${banner});
    background-size: 56% 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
  `;
  const Container11 = styled.div`
    width: 1920px;
    // height: 766px;
    width: 100%;
    background: linear-gradient(
      90.18deg,
      #1d79bb 0.13%,
      #1d79bb 24.19%,
      #1d79bb 44.58%,
      rgba(29, 121, 187, 0.97) 50.76%,
      rgba(29, 121, 187, 0) 99.82%
    );
    display: flex;
    background-image: url(${banner1});
    background-size: 56% 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
  `;
  const Container111 = styled.div`
    width: 1920px;
    // height: 766px;
    width: 100%;
    background: linear-gradient(
      90.18deg,
      #1d79bb 0.13%,
      #1d79bb 24.19%,
      #1d79bb 44.58%,
      rgba(29, 121, 187, 0.97) 50.76%,
      rgba(29, 121, 187, 0) 99.82%
    );
    display: flex;
    background-image: url(${banner2});
    background-size: 56% 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
  `;
  const Container1111 = styled.div`
    width: 1920px;
    // height: 766px;
    width: 100%;
    background: linear-gradient(
      90.18deg,
      #1d79bb 0.13%,
      #1d79bb 24.19%,
      #1d79bb 44.58%,
      rgba(29, 121, 187, 0.97) 50.76%,
      rgba(29, 121, 187, 0) 99.82%
    );
    display: flex;
    background-image: url(${banner3});
    background-size: 56% 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
  `;
  const Containers1 = styled.div`
    width: 1920px;
    // height: 766px;
    width: 100%;
    background: linear-gradient(
      90.18deg,
      #1d79bb 0.13%,
      #1d79bb 24.19%,
      #1d79bb 44.58%,
      rgba(29, 121, 187, 0.97) 50.76%,
      rgba(29, 121, 187, 0) 99.82%
    );
    padding: 5% 5%;
    display: flex;
    background-size: cover;
    @media (max-width: 768px) {
      align-items: center;
    }
  `;
  const Container2Heading = styled.div`
    font-family: Playfair Display;
    font-size: 64px;
    font-weight: 700;
    line-height: 108px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #ffffff;

    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 22px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0.5px;
      text-align: center;
      // color: yellow;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 48px;
      font-weight: 700;
      line-height: 78px;
      letter-spacing: 0.5px;
      text-align: left;
    }
  `;
  const Container2SubHeading = styled.div`
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #ffffff;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0.5px;
      text-align: left;
    }
  `;
  const Container2Button = styled.button`
    width: 238px;
    height: 63px;
    padding: 6px 36.27000427246094px 6px 36px;
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #1d79bb;
    margin-top: 20px;
    border: 0px;
    cursor: pointer;
    @media (max-width: 768px) {
      text-align: center;
      width: 113px;
      height: 33px;
      padding: 6px 15px 6px 17px;
      border-radius: 3px;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      // text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
    }
  `;
  const Containers = styled.div`
    width: ${isMobile() ? "100%" : "60%"};
    padding: 0% 5%;
    padding-top: 20px;
    @media (max-width: 768px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 70%;
    }
  `;

  return (
    <Container>
      <div className="slider-container">
        <Slider {...settings}>
          <Container1>
            <Containers1>
              <Containers>
                <Container2Heading>
                  Delivering Health, Empowering Lives
                </Container2Heading>
                <Container2SubHeading>
                  We deliver healthcare and empower lives through our
                  experienced team and digital platforms. Our services include
                  Pharma distribution, temperature-controlled storage, global
                  distribution, customs clearance, and track-and-trace
                  capabilities. With our commitment to excellence, we are a
                  trusted provider in India, making a positive impact on
                  healthcare.
                </Container2SubHeading>
                <Container2Button
                  onClick={() => {
                    navigate("aboutus");
                  }}
                >
                  Learn More
                </Container2Button>
              </Containers>
            </Containers1>
          </Container1>
          <Container11>
            <Containers1>
              <Containers>
                <Container2Heading>
                  Partnering for Better Healthcare Access
                </Container2Heading>
                <Container2SubHeading>
                  We deliver healthcare and empower lives through our
                  experienced team and digital platforms. Our services include
                  Pharma distribution, temperature-controlled storage, global
                  distribution, customs clearance, and track-and-trace
                  capabilities. With our commitment to excellence, we are a
                  trusted provider in India, making a positive impact on
                  healthcare.
                </Container2SubHeading>
                <Container2Button
                  onClick={() => {
                    navigate("aboutus");
                  }}
                >
                  Learn More
                </Container2Button>
              </Containers>
            </Containers1>
          </Container11>
          <Container111>
            <Containers1>
              <Containers>
                <Container2Heading>
                  Quality Products, Trusted Service
                </Container2Heading>
                <Container2SubHeading>
                  We deliver healthcare and empower lives through our
                  experienced team and digital platforms. Our services include
                  Pharma distribution, temperature-controlled storage, global
                  distribution, customs clearance, and track-and-trace
                  capabilities. With our commitment to excellence, we are a
                  trusted provider in India, making a positive impact on
                  healthcare.
                </Container2SubHeading>
                <Container2Button
                  onClick={() => {
                    navigate("aboutus");
                  }}
                >
                  Learn More
                </Container2Button>
              </Containers>
            </Containers1>
          </Container111>
          <Container1111>
            <Containers1>
              <Containers>
                <Container2Heading>
                  Health Solutions, Seamless Delivery
                </Container2Heading>
                <Container2SubHeading>
                  We deliver healthcare and empower lives through our
                  experienced team and digital platforms. Our services include
                  Pharma distribution, temperature-controlled storage, global
                  distribution, customs clearance, and track-and-trace
                  capabilities. With our commitment to excellence, we are a
                  trusted provider in India, making a positive impact on
                  healthcare.
                </Container2SubHeading>
                <Container2Button
                  onClick={() => {
                    navigate("aboutus");
                  }}
                >
                  Learn More
                </Container2Button>
              </Containers>
            </Containers1>
          </Container1111>
        </Slider>
      </div>
    </Container>
  );
}

export default Banners;
