import Slideshow from "../../Components/SlideShow";
import React from "react";
import { styled } from "styled-components";

function Trusted() {
  const Container = styled.div`padding:5% 5%`;
  return (
    <Container>
      <Slideshow />
    </Container>
  );
}

export default Trusted;
