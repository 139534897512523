import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Career from "../Pages/Career";
// import Solutions from '../components/Solutions';
// import Nopage from '../components/Nopage';
import Aboutus from "../Pages/Aboutus/Aboutus";
import Contactus from "../Pages/Contactus/Contactus";
import Partners from "../Pages/Parterners/Partners";

export default function Router() {
  return (
    
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/home" index element={<Home />} />
        <Route path="/" index element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/aboutus" element={<Aboutus />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        {/* <Route path="*" element={<Nopage />}></Route> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
