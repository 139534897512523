import React from "react";
import { styled } from "styled-components";
import presence1 from "../../Assets/Home/Presence/Group 29145.svg";
import presence2 from "../../Assets/Home/Presence/Rectangle 19114.svg";

function Presence() {
  const Container = styled.div`
    background-image: url("${presence2}");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5% 20px 5%;
  `;
  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 48px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0.03em;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0.03em;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 55px;
      letter-spacing: 0.03em;
      text-align: center;
    }
  `;
  const ContainerSubHeading = styled.div`
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0.5px;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.5px;
      text-align: center;
    }
  `;
  const ContainerImage = styled.img`
    width: 56%;
    height: 100%;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 70%;
      margin-top: 20px;
    }
  `;
  return (
    <Container>
      <ContainerHeading>Our Presence</ContainerHeading>
      <ContainerSubHeading>
        We’re proud of our wide-spread distribution network, spread across
        various states and cities of India. We firmly believe in making
        healthcare accessible to everyone and slowly, we’re moving towards our
        aim of establishing our presence in more regions, to reach as many
        people as we can. Currently, we’re operating in districts of Haryana,
        Punjab, Rajasthan, U.P and Delhi/NCR where we partner with hospitals,
        distributors and retailers to ensure the efficient and secure delivery
        of life-saving medications and healthcare products.
      </ContainerSubHeading>
      <ContainerImage src={presence1} />
    </Container>
  );
}

export default Presence;
