import Partner1 from '../../Assets/images/Partners/partner1.png';
import Partner2 from '../../Assets/images/Partners/partner2.png';
import Partner3 from '../../Assets/images/Partners/partner3.png';
import Partner4 from '../../Assets/images/Partners/partner4.png';
import Partner5 from '../../Assets/images/Partners/partner5.png';
import Partner6 from '../../Assets/images/Partners/partner6.png';
import Partner7 from '../../Assets/images/Partners/partner7.png';
import Partner8 from '../../Assets/images/Partners/partner8.png';
import Partner9 from '../../Assets/images/Partners/partner9.png';
import Partner10 from '../../Assets/images/Partners/partner10.png';
import Partner11 from '../../Assets/images/Partners/partner11.png';
import Partner12 from '../../Assets/images/Partners/partner12.png';
import Partner13 from '../../Assets/images/Partners/partner13.png';
import Partner14 from '../../Assets/images/Partners/partner14.png';
import Partner15 from '../../Assets/images/Partners/partner15.png';
import Partner16 from '../../Assets/images/Partners/partner16.png';
import Partner17 from '../../Assets/images/Partners/partner17.png';
import Partner18 from '../../Assets/images/Partners/partner18.png';
import Partner19 from '../../Assets/images/Partners/partner19.png';
import Partner20 from '../../Assets/images/Partners/partner20.png';
import Partner21 from '../../Assets/images/Partners/partner21.png';
import Partner22 from '../../Assets/images/Partners/partner22.png';
import Partner23 from '../../Assets/images/Partners/partner23.png';
import Partner24 from '../../Assets/images/Partners/partner24.png';
import Partner25 from '../../Assets/images/Partners/partner25.png';
import Partner26 from '../../Assets/images/Partners/partner26.png';
import Partner27 from '../../Assets/images/Partners/partner27.png';
import Partner28 from '../../Assets/images/Partners/partner28.png';
import Partner29 from '../../Assets/images/Partners/partner29.png';
import Partner30 from '../../Assets/images/Partners/partner30.png';
import Partner31 from '../../Assets/images/Partners/partner31.png';

const PartnersList = [
  Partner1,
  Partner2,
  Partner3,
  Partner4,
  Partner5,
  Partner6,
  Partner7,
  Partner8,
  Partner9,
  Partner10,
  Partner11,
  Partner12,
  Partner13,
  Partner14,
  Partner15,
  Partner15,
  Partner16,
  Partner17,
  Partner18,
  Partner19,
  Partner20,
  Partner21,
  Partner22,
  Partner23,
  Partner24,
  Partner25,
  Partner26,
  Partner27,
  Partner28,
  Partner29,
  Partner30,
  Partner31,
];
export default PartnersList;
