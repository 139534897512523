import { Grid } from "@mui/material";
import React from "react";
import lifeatpdpl1 from "../../Assets/Careers/Lifeatpdpl/Rectangle 19130.png";
import lifeatpdpl2 from "../../Assets/Careers/Lifeatpdpl/Rectangle 19132.png";
import lifeatpdpl3 from "../../Assets/Careers/Lifeatpdpl/Rectangle 19133.png";
import lifeatpdpl4 from "../../Assets/Careers/Lifeatpdpl/Rectangle 19134.png";
import lifeatpdpl5 from "../../Assets/Careers/Lifeatpdpl/ezgif-5-5de08e24d7 1.png";
import { styled } from "styled-components";
import isMobile from "../../Assets/isMobile";

function Lifeatpdpl() {
  const Container = styled.div`
    padding: 3.8% 5%;
  `;

  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 44px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
    }
  `;
  const ContainerImagev1 = styled.img`
    width: 100%;
    height: 500px;
    padding: 0px 20px 20px 0px;
    @media (max-width: 768px) {
      width: 100%;
      height: 300px;
      padding: 0px 10px 10px 0px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 100%;
      height: 353px;
    }
  `;
  const ContainerImage = styled.img`
    width: 100%;
    height: 100%;
  `;
  const ContainerImage1 = styled.img`
    width: 100%;
    height: 100%;
  `;
  const ContainerBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `;
  const ContainerBox1 = styled.div`
    width: 50%;
    padding: 0px 20px 20px 0px;
    // width: 50%;
    height: 250px;
    @media (max-width: 768px) {
      padding: 0px 10px 10px 0px;
      height: 150px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 50%;
      height: 172px;
    }
  `;
  const ContainerBoxv1 = styled.div`
    width: 50%;
    padding: 0px 20px 20px 0px;

    // width: 50%;
    height: 250px;
    @media (max-width: 768px) {
      padding: 0px 10px 10px 0px;
      height: 150px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 50%;
      height: 172px;
    }
  `;

  return (
    <Container>
      <ContainerHeading>Life At PDPL</ContainerHeading>
      <Grid container>
        <Grid item xs={4}>
          <ContainerImagev1 src={lifeatpdpl1} />
        </Grid>
        <Grid item xs={8}>
          <ContainerBox>
            {/* <Grid container>
              <Grid item xs={3}> */}
            <ContainerBox1>
              <ContainerImage src={lifeatpdpl5} />
            </ContainerBox1>
            <ContainerBoxv1>
              <ContainerImage1 src={lifeatpdpl3} />
            </ContainerBoxv1>
            <ContainerBox1>
              <ContainerImage src={lifeatpdpl2} />
            </ContainerBox1>
            <ContainerBoxv1>
              <ContainerImage1 src={lifeatpdpl4} />
            </ContainerBoxv1>
            {/* </Grid> */}
            {/* <Grid item xs={3}> */}
            {/* </Grid> */}
            {/* <Grid item xs={3}> */}
            {/* </Grid> */}
            {/* <Grid item xs={3}> */}
            {/* </Grid>
            </Grid> */}
          </ContainerBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Lifeatpdpl;
