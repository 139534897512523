import React from "react";
import styled from "styled-components";
import SparshUse from "../../Assets/images/sparsh.png";
import "../../Assets/index.css";

function Sparsh() {
  const VendorContainers = styled.div`
    position: relative;
    top: -70px;
    width: 100%;
    padding-top: 60px;
    padding-left: 7%;
  `;
  const VendorContainerHeading = styled.h4`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 149%;
    color: #1b1b1f;
    margin-bottom: -10px;
  `;
  const VendorContainer = styled.p`
    width: 500px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #1b1b1f;
  `;
  const CircularContainer = styled.div`
    height: 492px;
    width: 86%;
    border-radius: 0 700px 700px 0px;
    background: #0fb6d1;
    margin-right: auto;
  `;

  const Container = styled.div`
    margin-top: -430px;
    margin-bottom: 220px;
  `;

  return (
    <div style={{ marginTop: "50px", height: "492px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", height: "492px" }}>
          <CircularContainer />
          <Container>
            <VendorContainers>
              <VendorContainerHeading>
                Our Special Partner
              </VendorContainerHeading>
              <VendorContainer>
                As the exclusive C&F partner for GUFIC Bioscience Ltd in
                Northern-Central India, PDPL ensures quick and smoother delivery
                of high-quality medical products and guarantees reliable access
                to essential medications for better healthcare outcomes.
              </VendorContainer>
              <a
                href="../Assets/partners.pdf"
                key={"item"}
                style={{
                  width: "fit-content",
                  marginLeft: "-7.4px",
                  backgroundColor: "white",
                  color: "#177abb",
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="response"
              >
                <span>Learn More</span>
              </a>
            </VendorContainers>
          </Container>
        </div>
        <div style={{ width: "50%", alignItems: "center", height: "492px" }}>
          <img
            src={SparshUse}
            style={{
              width: "477px",
              height: "223px",
              margin: "18% 7% 20% 7%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Sparsh;
