import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import purposes from "../../Assets/images/purposes.png";

function PurposeValue() {
  const Heading = styled.div`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 114.5%;
    color: #23262f;
  `;
  const Container = styled.div`
    text-align: left;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 160%;
    letter-spacing: 0.05em;
    color: #4b95c7;
  `;
  const Topic = styled.span`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 160%;
    letter-spacing: 0.05em;
    color: #4b95c7;
  `;
  const TopicValue = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: black;
  `;
  return (
    <div>
      <Grid
        container
        style={{
          textAlign: "center",
          marginTop: "100px",
          padding: "0% 2% 0% 3.4%",
        }}
      >
        <Grid item sm={6} xs={12} style={{ paddingRight: "3.47%" }}>
          <Heading>Our Purpose</Heading>
          <Container style={{ textAlign: "left" }}>
            <TopicValue>
              <Topic>
                <img src={purposes} /> Reliability:
              </Topic>
              Trust is the foundation of our relationships. We understand the
              critical importance of safe and timely delivery of medicines.
              That's why we ensure timely and hassle-free distribution of all
              healthcare needs to providers and patients.
            </TopicValue>
          </Container>
          <Container>
            <TopicValue>
              <Topic>
                <img src={purposes} /> Excellence:
              </Topic>
              We strive for excellence in all that we do. We maintain the
              highest standards of quality and professionalism, ensuring that
              every aspect of our pharmaceutical distribution process is handled
              with utmost care and precision.
            </TopicValue>
          </Container>
          <Container>
            <TopicValue>
              <Topic>
                <img src={purposes} /> Integrity:
              </Topic>
              We believe in transparency, honesty, and accountability with all
              our stakeholders. Over the years of our operations, we have forged
              valuable relationships with manufacturers, providers and suppliers
              of healthcare.
            </TopicValue>
          </Container>
        </Grid>
        <Grid item sm={6} xs={12} style={{ paddingRight: "20px" }}>
          <Heading>Our Values</Heading>
          <Container>
            <TopicValue>
              <Topic>
                <img src={purposes} /> Vision:
              </Topic>
              Our values are deeply rooted in our vision and mission. We're
              committed to our vision to enhance human lives by providing
              genuine and meaningful healthcare products.
            </TopicValue>
          </Container>
          <Container>
            <TopicValue>
              <Topic>
                <img src={purposes} /> Mission:
              </Topic>
              Our mission is to facilitate the seamless flow of medicines and
              ensure safe delivery, thereby improving healthcare access and
              patient outcomes.
            </TopicValue>
            <TopicValue>
              With these values and a clear purpose, we strive to make a
              significant impact in pharmaceutical distribution, contributing to
              a healthier and more efficient healthcare system.
            </TopicValue>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default PurposeValue;
