import { Button, Grid } from '@mui/material';
import React, { useRef } from 'react';
import Enquiry from '../../Assets/images/Enquiry.png';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

function Enquiries() {
  const Heading = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 144.5%;
    align-items: center;
    color: #071112;
  `;
  const SubHeading = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 114.5%;
    color: #23262f;
  `;
  const SubText = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    color: #1b1b1f;
    margin-bottom: -22px;
  `;
  const Content = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-size: 20px;
    line-height: 160%;
    color: #4e5463;
    margin-bottom: 102px;
  `;
  const StyledComponent = styled(Grid)`
    box-sizing: border-box;
    background: #ffffff;
    border: 3px solid #d9d9d9;
    border-radius: 16px;
    padding: 25px;
  `;
  const Support = styled.p`
    width: 577px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    color: #1b1b1f;
    margin-left: auto;
    margin-right: auto;
  `;
  const FormInput = styled.p`
    height: 15.6px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    color: #071112;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 23px;
  `;
  const FormInputs = styled.input`
    margin-top: 20px;
    width: 100%;
    height: 40px;
    background: #e6e8ec;
    border-radius: 16px;
    border: 0px solid black;
    font-size: 20px;
    padding-left: 20px;
  `;
  const FormInputFeild = styled.textarea`
    margin-top: 20px;
    width: 100%;
    background: #e6e8ec;
    border-radius: 16px;
    border: 0px solid black;
    font-size: 24px;
    padding: 11px 5px 5px 20px;
  `;

  const form = useRef();
  const sendEmail = (e) => {
    emailjs.sendForm('service_z04891a', 'template_q610hap', form.current, 'bA57XgVn8bP5C6F16').then(
      (result) => {
        alert('email sent');
        form.current.reset();
      },
      (error) => {
        alert('error occur');
        console.log(error.text);
      },
    );
  };

  return (
    <div>
      <Grid container style={{ padding: '70px 70px 70px 102px' }}>
        <Grid item xs={6}>
          <Heading>Contact Us</Heading>
          <SubHeading>Enquiries</SubHeading>
          <SubText>Want to partner with us or need support?</SubText>
          <Content>
            Contact us at - 9671480888 / +91 124-4014675 <br />
            Mail us at - info@plusdistributions.in
          </Content>
        </Grid>
        <Grid item xs={6}>
          <img src={Enquiry} />
        </Grid>
        <StyledComponent item xs={5.5}>
          <form ref={form}>
            <Grid container>
              <Grid item xs={3.4}>
                <FormInput>Name</FormInput>
              </Grid>
              <Grid item xs={8.6}>
                <FormInputs name='name' type='text' />
              </Grid>
              <Grid item xs={3.4}>
                <FormInput>Email ID</FormInput>
              </Grid>
              <Grid item xs={8.6}>
                <FormInputs name='email' type='text' />
              </Grid>
              <Grid item xs={3.4}>
                <FormInput>Subject</FormInput>
              </Grid>
              <Grid item xs={8.6}>
                <FormInputs name='subject' type='text' />
              </Grid>
              <Grid item xs={3.4}>
                <FormInput>Messages</FormInput>
              </Grid>
              <Grid item xs={8.6}>
                <FormInputFeild name='message' type='text' rows={6} cols={5} />
              </Grid>
              <Grid item xs={3.4}></Grid>
              <Grid item xs={8.6}>
                <a
                  onClick={() => {
                    sendEmail();
                  }}
                  key={'item'}
                  style={{
                    width: 'fit-content',
                    marginLeft: '0px',
                  }}
                >
                  <span>Send</span>
                </a>
              </Grid>
            </Grid>
          </form>
        </StyledComponent>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <div style={{ display: 'flex', height: '160px' }}>
            <div style={{ width: '31.5%' }}>
              <SubHeading>Locate Us</SubHeading>
            </div>
            <div style={{ width: '68.5%', paddingLeft: '0px' }}>
              <Content>
                Plus Distribution Pvt. Ltd, Company no: 581, Pace City II, Sector 37, Gurugram,
                Haryana 122004{' '}
              </Content>
            </div>
          </div>
          <div>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.5124600147733!2d76.9943061!3d28.4339645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d17bdf3ddf591%3A0xbdbb9a6f0115739d!2sPlus%20Distribution%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1685521082009!5m2!1sen!2sin'
              width='100%'
              height='383px'
              style={{ border: '0' }}
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
        </Grid>
      </Grid>
      <div style={{ width: '100%', height: '110px', textAlign: 'center' }}>
        <Support>
          For support, sales and other enquiry, reach out to us We are always happy to serve
        </Support>
      </div>
    </div>
  );
}

export default Enquiries;
