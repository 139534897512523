import { Grid } from "@mui/material";
import React from "react";
import footer from "../../Assets/Footer/logo.svg";
import footer1 from "../../Assets/Footer/Group 29134 (1).svg";
import footer2 from "../../Assets/Footer/Group 29135 (1).svg";
import footer3 from "../../Assets/Footer/Group 29152 (1).svg";
import footer4 from "../../Assets/Footer/Group 29153 (1).svg";
import footer5 from "../../Assets/Footer/Group 29154 (1).svg";
import footer6 from "../../Assets/Footer/Group 29155 (1).svg";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const ContainerImage = styled.img`
    width: 278px;
    height: 131px;
    @media only screen and (min-device-width: 480px) {
      width: 114px;
      height: 54px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 114px;
      height: 44.503726959228516px;
    }
  `;
  const ContainerHeading = styled.p`
    font-family: Playfair Display;
    font-size: 22px;
    line-height: 36px;
    width: 70%;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.5px;
    text-align: left;
    margin: 0;
    @media only screen and (min-device-width: 480px) {
      font-size: 32px;
      width: 100%;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-size: 32px;
      width: 100%;
    }
  `;
  const ContianerHeading1 = styled.p`
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.03em;
    text-align: left;
    cursor: pointer;
  `;
  const ContainerSubHeading = styled.p`
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
    cursor: pointer;
  `;
  const Contianers = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-start;
  `;
  const ContainersHeading = styled.p`
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.03em;
    margin: 0;
    @media only screen and (min-width: 768px) {
      font-size: 18px;
      width:60%;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 18px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0.03em;
      text-align: left
      width:60%;
    }
  `;
  const ContainersImage = styled.img`
    width: 38px;
    height: 38px;
    margin-top: 10px;
    @media only screen and (min-width: 768px) {
      width: 38px;
      height: 38px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 30px;
      height: 30px;
    }
  `;
  const Copyright = styled.div`
    text-align: center;
  `;
  const Copyrightbaseline = styled.div`
    margin-top: 20px;
    width: 100%;
    height: 2px;
    border: 1px solid #676767;
  `;
  const CopyrightContent = styled.div`
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.03em;
    margin: 20px 0%;
    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0.03em;
      text-align: center;
    }
  `;
  return (
    <div
      style={{
        padding: "4% 5% 4px 5%",
        backgroundImage: "url('../Assets/footers.svg')",
        backgroundSize: "cover",
        backgroundColor: "#e0ecfc",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={5} lg={4}>
          <ContainerImage src={footer} />
          <ContainerHeading>
            Delivering Health, Empowering Lives
          </ContainerHeading>
        </Grid>
        <Grid item xs={6} sm={4} lg={2.5}>
          <ContianerHeading1>Our Company</ContianerHeading1>
          <ContainerSubHeading
            onClick={() => {
              navigate("/Home");
            }}
          >
            Home
          </ContainerSubHeading>
          <ContainerSubHeading
            onClick={() => {
              navigate("/aboutus");
            }}
          >
            About Us
          </ContainerSubHeading>
          <ContainerSubHeading
            onClick={() => {
              navigate("/partners");
            }}
          >
            Partners
          </ContainerSubHeading>
          <ContainerSubHeading
            onClick={() => {
              navigate("/career");
            }}
          >
            Careers
          </ContainerSubHeading>
          <ContainerSubHeading>Terms and Conditions</ContainerSubHeading>
        </Grid>
        <Grid item xs={6} sm={3} lg={2.5}>
          <ContianerHeading1>Support</ContianerHeading1>
          <ContainerSubHeading>Security</ContainerSubHeading>
          <ContainerSubHeading>Privacy</ContainerSubHeading>
          <ContainerSubHeading>Sustainability</ContainerSubHeading>
          <ContainerSubHeading>News</ContainerSubHeading>
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
          <ContianerHeading1
            onClick={() => {
              navigate("/contactus");
            }}
          >
            Contact us
          </ContianerHeading1>
          <Contianers>
            <ContainersImage src={footer3} style={{ marginTop: "0px" }} />
            <ContainersHeading>info@plusdistributions.in</ContainersHeading>
          </Contianers>
          <Contianers>
            <ContainersImage src={footer4} />
            <ContainersHeading>9671480888 ,+91124-4014675</ContainersHeading>
          </Contianers>
          <Contianers>
            <ContainersImage src={footer5} />
            <ContainersHeading>
              Plus Distribution Pvt. Ltd, Company no: 581, Pace City II, Sector
              37, Gurugram, Haryana 122004
            </ContainersHeading>
          </Contianers>
          <Contianers>
            <ContainersImage src={footer1} />
            <ContainersImage src={footer2} />
            <ContainersImage src={footer6} />
          </Contianers>
        </Grid>
      </Grid>
      <Copyrightbaseline />
      <Copyright>
        <CopyrightContent>
          ©2023 plusdistributions | All Rights Reserved
        </CopyrightContent>
      </Copyright>
    </div>
  );
}

export default Footer;
