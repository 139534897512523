import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Ticker from "../../Components/Ticker";
import vendor from "../../Assets/images/Intro.png";
import isMobile from "../../Assets/isMobile";

function Infos() {
  const UpperContainers = styled.div`
    width: 95%;
    height: 200px;
    background: #1d79bb;
    border-radius: 32px 32px 0px 0px;
    text-align: center;
    margin-bottom: 40px;
    margin-left: 2.5%;
    padding-bottom: 100px;
    z-index: 6;
    position: sticky;
}

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
      padding-bottom: 100px;
      margin-left: 0%;
      height: 125px;
    }
  `;

  const ClientNumber = styled.div`
    height: 70px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #fff;

    @media (max-width: 768px) {
      height: 100%;
      font-size: 24px;
      height: 11%;
    }
  `;
  const ClientTopic = styled.div`
    height: 28px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #fff;
    flex: none;
    order: 1;
    flex-grow: 0;
    @media (max-width: 768px) {
      height: 100%;
      font-size: 16px;
    }
  `;
  return (
    <div
      style={
        isMobile()
          ? {
              marginBottom: "-60px",
              marginTop: "2.3%",
              // padding: 20px;
              position: "relative",
            }
          : { marginBottom: "-60px", marginTop: "-8.11%" }
      }
    >
      <UpperContainers>
        <Grid container>
          <Grid
            item
            xs={4}
            style={{ marginTop: `${isMobile() ? "-2.9%" : "-3%"}` }}
          >
            <ClientNumber>
              <Ticker className="count" end={2800} suffix="+" />
            </ClientNumber>
            <ClientTopic>Deliveries across India</ClientTopic>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ marginTop: `${isMobile() ? "-2.9%" : "-3%"}` }}
          >
            <ClientNumber>
              <Ticker className="count" end={4} suffix="+" />
            </ClientNumber>
            <ClientTopic>Warehouses across India</ClientTopic>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ marginTop: `${isMobile() ? "-2.9%" : "-3%"}` }}
          >
            <ClientNumber>
              <Ticker className="count" end={50} suffix="+" />
            </ClientNumber>
            <ClientTopic>Trusted partners</ClientTopic>
          </Grid>
        </Grid>
      </UpperContainers>
    </div>
  );
}

export default Infos;
