import React from "react";
import Collapse from "../../Components/Collapse";
import roles1 from "../../Assets/Careers/Roles/Vector (3).svg";
import roles2 from "../../Assets/Careers/Roles/Vector (4).svg";

import roles3 from "../../Assets/Careers/Roles/noun-clinic-4077763 1 (1).svg";

import roles4 from "../../Assets/Careers/Roles/noun-clinic-4077763 1.svg";

import roles5 from "../../Assets/Careers/Roles/pen 1 (1).svg";

import roles6 from "../../Assets/Careers/Roles/pen 1.svg";
import { styled } from "styled-components";

function Roles() {
  const Container = styled.div`
    padding: 3.8% 5%;
  `;

  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 44px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
    }
  `;
  const Containers = styled.div`
    display: flex;
    gap: 20px;
  `;
  const Containers1 = styled.div``;
  const Containers1Image = styled.img`
    width: 73px;
    height: 43px;
    @media (max-width: 768px) {
      width: 45px;
      height: 27px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 60px;
      height: 36px;
    }
  `;
  const Containers2 = styled.div``;
  const Containers2Heading = styled.div`
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  `;
  const Containers2SubHeading = styled.div`
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  `;

  return (
    <Container>
      <ContainerHeading>Open Roles at PDPL</ContainerHeading>
      <Collapse
        cardContent={""}
        title={
          <Containers>
            <Containers1>
              <Containers1Image src={roles1} />
            </Containers1>
            <Containers2>
              <Containers2Heading>Front-end Developer</Containers2Heading>
              <Containers2SubHeading>Gurgaon,Haryana</Containers2SubHeading>
            </Containers2>
          </Containers>
        }
      />
      <Collapse
        cardContent={""}
        title={
          <Containers>
            <Containers1>
              <Containers1Image src={roles5} />
            </Containers1>
            <Containers2>
              <Containers2Heading>UX/UI Designer</Containers2Heading>
              <Containers2SubHeading>Gurgaon,Haryana</Containers2SubHeading>
            </Containers2>
          </Containers>
        }
      />
      <Collapse
        cardContent={""}
        title={
          <Containers>
            <Containers1>
              <Containers1Image src={roles4} />
            </Containers1>
            <Containers2>
              <Containers2Heading>HR Executive</Containers2Heading>
              <Containers2SubHeading>Gurgaon,Haryana</Containers2SubHeading>
            </Containers2>
          </Containers>
        }
      />
      <Collapse
        cardContent={""}
        title={
          <Containers>
            <Containers1>
              <Containers1Image src={roles2} />
            </Containers1>
            <Containers2>
              <Containers2Heading>Front-end Developer</Containers2Heading>
              <Containers2SubHeading>Gurgaon,Haryana</Containers2SubHeading>
            </Containers2>
          </Containers>
        }
      />
      <Collapse
        cardContent={""}
        title={
          <Containers>
            <Containers1>
              <Containers1Image src={roles6} />
            </Containers1>
            <Containers2>
              <Containers2Heading>UX/UI Designer</Containers2Heading>
              <Containers2SubHeading>Gurgaon,Haryana</Containers2SubHeading>
            </Containers2>
          </Containers>
        }
      />
      <Collapse
        cardContent={""}
        title={
          <Containers>
            <Containers1>
              <Containers1Image src={roles3} />
            </Containers1>
            <Containers2>
              <Containers2Heading>HR Executive</Containers2Heading>
              <Containers2SubHeading>Gurgaon,Haryana</Containers2SubHeading>
            </Containers2>
          </Containers>
        }
      />
    </Container>
  );
}

export default Roles;
