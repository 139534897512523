import React from "react";
import Companys1 from "../../Assets/Home/Company/Group 1269 (3).svg";
import Companys2 from "../../Assets/Home/Company/Group 29166 (1).svg";
import Companys3 from "../../Assets/Home/Company/Group 29166 (2).svg";
import Companys4 from "../../Assets/Home/Company/Mask group (14).svg";
import Companys5 from "../../Assets/Home/Company/image 18.svg";
import { styled } from "styled-components";

function Company() {
  const Container = styled.div`
    background-image: url("${Companys4}");
    background-size: cover;
    width: 100%;
    // height: 500px;
    padding: 5%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
  `;

  const Containers = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 2%;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  `;
  const ContainerHeading = styled.div`
    width: 65%;
    font-family: Playfair Display;
    font-size: 48px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0.03em;
    text-align: center;
    @media (max-width: 480px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.03em;
      text-align: center;
      width: 73%;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: 0.03em;
      text-align: center;
    }
  `;
  const ContainerBox = styled.div`
    width: 252px;
    // height: 277px;
    box-shadow: 0px 4px 12px 0px #0000001a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 5%;
    background-color: #ffffff;

    @media (max-width: 768px) {
      width: 150px;
      // height: 173px;
      border-radius: 6px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 171px;
      height: 191px;
    }
  `;
  const ContainerBoxImage = styled.img`
    width: 186px;
    height: 131px;
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  `;
  const ContainerBoxCount = styled.div`
    font-family: Helvetica;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;

    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
    }
  `;
  const ContainerBoxHeading = styled.div`
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }
  `;
  return (
    <Container>
      <ContainerHeading>
        We are the best pharmaceutical distributor in India
      </ContainerHeading>

      <Containers>
        <ContainerBox>
          <ContainerBoxImage src={Companys2} />
          <ContainerBoxCount>30+</ContainerBoxCount>
          <ContainerBoxHeading>Warehouses Across India</ContainerBoxHeading>
        </ContainerBox>
        <ContainerBox>
          <ContainerBoxImage src={Companys1} />
          <ContainerBoxCount>30+</ContainerBoxCount>
          <ContainerBoxHeading>Warehouses Across India</ContainerBoxHeading>
        </ContainerBox>
        <ContainerBox>
          <ContainerBoxImage src={Companys5} />
          <ContainerBoxCount>30+</ContainerBoxCount>
          <ContainerBoxHeading>Warehouses Across India</ContainerBoxHeading>
        </ContainerBox>
        <ContainerBox>
          <ContainerBoxImage src={Companys2} />
          <ContainerBoxCount>30+</ContainerBoxCount>
          <ContainerBoxHeading>Warehouses Across India</ContainerBoxHeading>
        </ContainerBox>
      </Containers>
    </Container>
  );
}

export default Company;
