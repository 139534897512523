import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

function Partners() {
  const navigate = useNavigate();
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5%;
    position: relative;
  `;
  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0.03em;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0.03em;
      text-align: left;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 53px;
      letter-spacing: 0.03em;
      text-align: center;
    }
  `;

  const ContainerSubHeading = styled.div`
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0.15007999539375305px;
    text-align: center;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
  `;

  const Containers = styled.div`
    display: flex;
    gap: 20px;
  `;

  const ContainerBox = styled.div`
    width: 100%;
    padding: 25px;
    @media (max-width: 768px) {
      padding: 15px;
    }
  `;
  const ContainerBoxHeading = styled.div`
    font-family: Helvetica;
    font-size: 48px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0.15007999539375305px;
    text-align: center;
    color: #1d79bb;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 22px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 32px;
      font-weight: 700;
      line-height: 37px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
  `;

  const ContainerBoxSubHeading = styled.div`
    font-family: Playfair Display;
    font-size: 32px;
    font-weight: 600;
    line-height: 43px;
    letter-spacing: 0.15007999539375305px;
    text-align: center;
    color: #1d79bb;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
  `;
  const ContainerButton = styled.button`
    width: 238px;
    height: 63px;
    padding: 6px 36.27000427246094px 6px 36px;
    border-radius: 5px;
    background-color: #1d79bb;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
    @media (max-width: 768px) {
      width: fit-content;
      height: 33px;
      padding: 6px 17px 6px 17px;
      border-radius: 3px;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: center;
    }

    @media (min-width: 481px) and (max-width: 1300px) {
      width: fit-content;
      // height: 41px;
      // padding: 10px 18px 10px 19px;
      border-radius: 5px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      // text-align: left;
    }
  `;

  const ContainerCircle1 = styled.div`
    width: 216px;
    height: 324px;
    border-radius: 700px 0px 0px 700px;
    background-color: #c5e5fd;
    position: absolute;
    top: -30%;
    right: 0;
    z-index: -1;
    @media (max-width: 768px) {
      width: 120px;
      height: 220px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 158px;
      height: 237px;
    }
  `;
  const ContainerCircle2 = styled.div`
    width: 216px;
    height: 324px;
    border-radius: 0px 700px 700px 0px;
    angle: -180 deg;
    background-color: #c5e5fd;
    position: absolute;
    position: absolute;
    bottom: -30%;
    left: 0;
    z-index: -1;
    @media (max-width: 768px) {
      width: 120px;
      height: 220px;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: 158px;
      height: 237px;
    }
  `;

  return (
    <Container>
      <ContainerCircle1 />
      <ContainerCircle2 />
      <ContainerHeading>Why partner with us</ContainerHeading>
      <ContainerSubHeading>
        As a leading pharmaceutical distributor, PDPL offers unparalleled
        expertise in to its partners. With a solid reputation for trust and
        reliability, we are the preferred partner for hospitals, medical stores,
        and healthcare facilities nationwide. Our vast network, powered by
        state-of-the-art technology, guarantees a seamless process for
        collecting, warehousing, and delivering medicines with the utmost
        precision and quality control.
      </ContainerSubHeading>
      <Containers>
        <ContainerBox>
          <ContainerBoxHeading>400+</ContainerBoxHeading>
          <ContainerBoxSubHeading>Clients across India</ContainerBoxSubHeading>
        </ContainerBox>
        <ContainerBox>
          <ContainerBoxHeading>400+</ContainerBoxHeading>
          <ContainerBoxSubHeading>Clients across India</ContainerBoxSubHeading>
        </ContainerBox>
        <ContainerBox>
          <ContainerBoxHeading>400+</ContainerBoxHeading>
          <ContainerBoxSubHeading>Clients across India</ContainerBoxSubHeading>
        </ContainerBox>
      </Containers>
      <ContainerButton
        onClick={() => {
          navigate("/contactus");
        }}
      >
        Contact Us
      </ContainerButton>
    </Container>
  );
}

export default Partners;
