import React from "react";
import styled from "styled-components";
import Networks from "../../Assets/images/Network.png";
import { Grid } from "@mui/material";

function OurPartner() {
  const Heading = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 114.5%;
    color: #23262f;
    // margin-top: 123px;
    margin-bottom: 5px;
    width: max-content;
  `;
  const NetworkContainer = styled.div`
  background-image=url(${Networks})
  position: absolute;
height: 470px;
left: 0px;
top: 3017px;
background: rgba(252, 252, 253, 0.3);`;
  const Contents = styled.p`
    width: 505px;
    left: 191px;
    top: 3155px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #1b1b1f;
  `;
  return (
    <div style={{ marginBottom: "-20px" }}>
      <NetworkContainer
        style={{
          backgroundImage: `url("../Images/Network.png")`,
        }}
      >
        <Grid
          container
          style={{
            backgroundImage: `url("../Images/transparent.png")`,
            width: "700px",
            height: "100%",
          }}
        >
          <Grid item xs={6} style={{ padding: "33px 191px 36px 100px" }}>
            <Heading>Our Partners</Heading>
            <Contents>
              Partnerships have been the key driving force behind PDPL’s growth
              story. We collaborate with manufacturers, distributors, and
              suppliers to deliver high-quality medical requirements. Together,
              we cater to diverse needs, ensuring unwavering dedication to
              customers and strive to make a global impact through trusted
              alliances.
            </Contents>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </NetworkContainer>
    </div>
  );
}

export default OurPartner;
