import { useState } from "react";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import isMobile from "../Assets/isMobile";
import "./collapses.css";

export default function Collapses({ cardContent, title }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card
        sx={{
          minWidth: 300,
          border: "1px solid rgba(211,211,211,0.6)",
          boxShadow: "0px 8px 24px 0px #0000001A",
          borderRadius: "20px",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <CardHeader
          title={title}
          action={
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="large"
              style={{ marginTop: isMobile() ? "10%" : "20%" }}
            >
              {open ? (
                <KeyboardArrowUpIcon className="svg_icons" />
              ) : (
                <KeyboardArrowDownIcon className="svg_icons" />
              )}
            </IconButton>
          }
        ></CardHeader>
        <div
          style={{
            backgroundColor: "rgba(211,211,211,0.4)",
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent>{cardContent}</CardContent>
          </Collapse>
        </div>
      </Card>
    </>
  );
}
