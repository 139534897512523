import React from "react";
import Banners from "./Home/Banners";
import Company from "./Home/Company";
import Whatwedo from "./Home/Whatwedo";
import Presence from "./Home/Presence";
import Partners from "./Home/Partners";
import Service from "./Home/Service";
import Trusted from "./Home/Trusted";
import Whattheysay from "./Home/Whattheysay";

function Home() {
  return (
    <div>
      <Banners />
      <Company />
      <Whatwedo />
      <Presence />
      <Partners />
      <Service />
      <Trusted />
      <Whattheysay />
    </div>
  );
}

export default Home;
