import React from "react";
import styled from "styled-components";
import Networks from "../../Assets/images/Network.png";
import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import isMobile from "../../Assets/isMobile";

function Network() {
  const Heading = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 114.5%;
    color: #23262f;
    margin-left: 7%;
    margin-bottom: 5px;
    margin-top: 60%;
  `;
  const NetworkContainer = styled.div`
    background-image=url(${Networks})
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 3017px;
    background: rgba(252, 252, 253, 0.3);
    @media (max-width: 768px) {
      height: 100%;
    }
  `;

  const Contents = styled.p`
    width: 505px;
    left: 191px;
    top: 3155px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #1b1b1f;
    @media (max-width: 768px) {
      width: 100%;
    }
  `;

  const Settings = {
    autoPlay: true,
    animation: "slide",
    indicators: true,
    duration: 500,
    navButtonsAlwaysVisible: false,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true,
  };
  return (
    <div>
      <Heading>Our Network</Heading>
      <Carousel {...Settings}>
        <NetworkContainer
          style={{
            backgroundImage: `url("../warehouses/warehouse.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            style={{
              backgroundImage: `url("../Images/transparent.png")`,
              width: isMobile() ? "100%" : "100%",
              height: "100%",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: isMobile()
                  ? "5px 11px 36px 7%"
                  : "33px 191px 36px 100px",
              }}
            >
              <Contents>
                With its seamless supply chain network and robust stakeholder
                partnerships, PDPL brings the best distribution services in the
                healthcare sector.
              </Contents>
              <Contents>
                Our state-of-the-art warehouses cater to wholesale and retail
                needs. Strategically located in Panipat, Delhi, Noida, Gurgaon,
                and Dehradun, they have a combined capacity of 60,000 sq.ft.
              </Contents>
              <Contents>
                We collect medicines directly at the source, guaranteeing
                authentic,reliable and timely delivery.
              </Contents>
              {/* <Contents>
                We have strategically positioned warehouse facilities equipped
                with state-of-the-art technology and adhering to strict storage
                regulations, ensuring the integrity and quality of the
                medications.
              </Contents> */}
            </Grid>
            <Grid item sm={0} xs={6}></Grid>
          </Grid>
        </NetworkContainer>
        <NetworkContainer
          style={{
            backgroundImage: `url("../warehouses/warehouse1.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            style={{
              backgroundImage: `url("../Images/transparent.png")`,
              width: isMobile() ? "100%" : "700px",
              height: "100%",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: isMobile()
                  ? "5px 11px 36px 7%"
                  : "33px 191px 36px 100px",
              }}
            >
              <Contents>
                With its seamless supply chain network and robust stakeholder
                partnerships, PDPL brings the best distribution services in the
                healthcare sector.
              </Contents>
              <Contents>
                Our state-of-the-art warehouses cater to wholesale and retail
                needs. Strategically located in Panipat, Delhi, Noida, Gurgaon,
                and Dehradun, they have a combined capacity of 60,000 sq.ft.
              </Contents>
              <Contents>
                We collect medicines directly at the source, guaranteeing
                authentic,reliable and timely delivery.
              </Contents>
              {/* <Contents>
                We have strategically positioned warehouse facilities equipped
                with state-of-the-art technology and adhering to strict storage
                regulations, ensuring the integrity and quality of the
                medications.
              </Contents> */}
            </Grid>
            <Grid item sm={0} xs={6}></Grid>
          </Grid>
        </NetworkContainer>
        <NetworkContainer
          style={{
            backgroundImage: `url("../warehouses/warehouse2.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            style={{
              backgroundImage: `url("../Images/transparent.png")`,
              width: isMobile() ? "100%" : "700px",
              height: "100%",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: isMobile()
                  ? "5px 11px 36px 7%"
                  : "33px 191px 36px 100px",
              }}
            >
              <Contents>
                With its seamless supply chain network and robust stakeholder
                partnerships, PDPL brings the best distribution services in the
                healthcare sector.
              </Contents>
              <Contents>
                Our state-of-the-art warehouses cater to wholesale and retail
                needs. Strategically located in Panipat, Delhi, Noida, Gurgaon,
                and Dehradun, they have a combined capacity of 60,000 sq.ft.
              </Contents>
              <Contents>
                We collect medicines directly at the source, guaranteeing
                authentic,reliable and timely delivery.
              </Contents>
              {/* <Contents>
                We have strategically positioned warehouse facilities equipped
                with state-of-the-art technology and adhering to strict storage
                regulations, ensuring the integrity and quality of the
                medications.
              </Contents> */}
            </Grid>
            <Grid item sm={0} xs={6}></Grid>
          </Grid>
        </NetworkContainer>
        <NetworkContainer
          style={{
            backgroundImage: `url("../warehouses/warehouse3.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            style={{
              backgroundImage: `url("../Images/transparent.png")`,
              width: isMobile() ? "100%" : "700px",
              height: "100%",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: isMobile()
                  ? "5px 11px 36px 7%"
                  : "33px 191px 36px 100px",
              }}
            >
              <Contents>
                With its seamless supply chain network and robust stakeholder
                partnerships, PDPL brings the best distribution services in the
                healthcare sector.
              </Contents>
              <Contents>
                Our state-of-the-art warehouses cater to wholesale and retail
                needs. Strategically located in Panipat, Delhi, Noida, Gurgaon,
                and Dehradun, they have a combined capacity of 60,000 sq.ft.
              </Contents>
              <Contents>
                We collect medicines directly at the source, guaranteeing
                authentic,reliable and timely delivery.
              </Contents>
              {/* <Contents>
                We have strategically positioned warehouse facilities equipped
                with state-of-the-art technology and adhering to strict storage
                regulations, ensuring the integrity and quality of the
                medications.
              </Contents> */}
            </Grid>
            <Grid item sm={0} xs={6}></Grid>
          </Grid>
        </NetworkContainer>
        <NetworkContainer
          style={{
            backgroundImage: `url("../warehouses/warehouse4.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            style={{
              backgroundImage: `url("../Images/transparent.png")`,
              width: isMobile() ? "100%" : "700px",
              height: "100%",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: isMobile()
                  ? "5px 11px 36px 7%"
                  : "33px 191px 36px 100px",
              }}
            >
              <Contents>
                With its seamless supply chain network and robust stakeholder
                partnerships, PDPL brings the best distribution services in the
                healthcare sector.
              </Contents>
              <Contents>
                Our state-of-the-art warehouses cater to wholesale and retail
                needs. Strategically located in Panipat, Delhi, Noida, Gurgaon,
                and Dehradun, they have a combined capacity of 60,000 sq.ft.
              </Contents>
              <Contents>
                We collect medicines directly at the source, guaranteeing
                authentic,reliable and timely delivery.
              </Contents>
              {/* <Contents>
                We have strategically positioned warehouse facilities equipped
                with state-of-the-art technology and adhering to strict storage
                regulations, ensuring the integrity and quality of the
                medications.
              </Contents> */}
            </Grid>
            <Grid item sm={0} xs={6}></Grid>
          </Grid>
        </NetworkContainer>
        <NetworkContainer
          style={{
            backgroundImage: `url("../warehouses/warehouse5.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            style={{
              backgroundImage: `url("../Images/transparent.png")`,
              width: isMobile() ? "100%" : "700px",
              height: "100%",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: isMobile()
                  ? "5px 11px 36px 7%"
                  : "33px 191px 36px 100px",
              }}
            >
              <Contents>
                With its seamless supply chain network and robust stakeholder
                partnerships, PDPL brings the best distribution services in the
                healthcare sector.
              </Contents>
              <Contents>
                Our state-of-the-art warehouses cater to wholesale and retail
                needs. Strategically located in Panipat, Delhi, Noida, Gurgaon,
                and Dehradun, they have a combined capacity of 60,000 sq.ft.
              </Contents>
              <Contents>
                We collect medicines directly at the source, guaranteeing
                authentic,reliable and timely delivery.
              </Contents>
              {/* <Contents>
                We have strategically positioned warehouse facilities equipped
                with state-of-the-art technology and adhering to strict storage
                regulations, ensuring the integrity and quality of the
                medications.
              </Contents> */}
            </Grid>
            <Grid item sm={0} xs={6}></Grid>
          </Grid>
        </NetworkContainer>
      </Carousel>
    </div>
  );
}

export default Network;
