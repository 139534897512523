import React from "react";
import styled from "styled-components";
import vendor from "../../Assets/images/warehouse6.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import isMobile from "../../Assets/isMobile";
import "./index1.css";

function Intro() {
  const navigate = useNavigate();
  const Introcmp = styled.div`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 144.5%;
    align-items: center;
    color: #071112;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 0px;
      color: black;
    }
  `;
  const Introcontent = styled.div`
    width: 100%;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    color: #000;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 0px;
      color: black;
      padding: 10px;
    }
  `;

  return (
    <div class="video-container">
      <video
        className="videoTag"
        autoPlay
        loop
        muted
        width="100%"
        poster={vendor}
      >
        <source
          src={
            "https://drive.google.com/uc?id=1GOiTrgvh_ArtvDpW3JZHLOjlaDBkOyvA"
          }
          type="video/mp4"
        />
      </video>

      <div className="overlay">
        <Introcmp>We are PDPL</Introcmp>
        <Introcontent>
          At PDPL,we've been Delivering Health and Empowering Lives for over 25
          years. A trusted pharmaceutical distributor in India, PDPL was founded
          in 1993 by Mr. Anil Kumar and Mr. Ashwani Kumar Bhatia. We prioritize
          genuine healthcare products and operate under a strong regulatory
          framework as Plus Distribution Pvt. Ltd. With unwavering reliability
          and a commitment to customer satisfaction, we have established
          ourselves as a leading pharma distributor in India.
        </Introcontent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginBottom: isMobile() ? "" : "7%",
          }}
        >
          <a
            onClick={() => {
              navigate("/contactus");
            }}
            style={{ width: "146px" }}
            className="response"
          >
            <span>Get in touch</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Intro;
