import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Award from "../../Assets/images/Awards.png";
import isMobile from "../../Assets/isMobile";

function Awards() {
  const CircularContainer = styled.div`
    height: 500px;
    width: 400px;
    border-radius: 500px 0 0 500px;
    background: #0ec7ae;
    margin-left: auto;
    @media (max-width: 768px) {
      width: 60%;
      position: relative;
      z-index: -1;
    }
  `;
  const VendorContainers = styled.div`
    position: relative;
    top: -505px;
    width: 505px;
    padding-top: 60px;
    @media (max-width: 768px) {
      position: static;
      margin-top: -550px;
      width: 100%;
      margin-bottom: 33%;
      color: black;
    }
  `;
  const VendorContainerHeading = styled.h4`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 149%;
    color: #1b1b1f;
    margin-bottom: -10px;
    padding-left: 20px;
    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 0px;
      width: 100%;
      font-weight: 800;
    }
  `;
  const VendorContainer = styled.p`
    font-family: "Avenir";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #1b1b1f;
    padding-left: 20px;
    width: 650px;
    @media (max-width: 768px) {
      font-size: 16px;
      width: 100%;
      font-weight: 700;
      padding-right: 20px;
    }
  `;
  const Container = styled.div`
    text-align: left;
    height: 183px;
    padding: 6%;
    @media (max-width: 768px) {
      text-align: left;
      height: 183px;
      padding: 6%;
      background-color: white;
      margin: 13px;
      background: #ffffff;
      box-shadow: 10px 10px 24px rgb(102 102 102 / 10%);
      border-radius: 8px;
    }
  `;
  const Topic = styled.div`
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0px;
    font-feature-settings: "liga" off, "kern" off;
    color: #000000;
    width: 274px;
    @media (max-width: 768px) {
      line-height: 100%;
      width: 100%;
      font-size: 16px;
    }
  `;
  const SubTopic = styled.div`
    font-family: "Avenir";
    font-style: normal;
    font-weight: 400;
    color: #757575;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  `;

  return (
    <>
      {isMobile() ? (
        <Grid container>
          <CircularContainer />
          <VendorContainers>
            <VendorContainerHeading>
              Awards and Recognitions
            </VendorContainerHeading>
            <VendorContainer>
              PDPL has garnered several prestigious awards and recognitions for
              its exceptional performance in the field of pharmaceutical
              distribution. These accolades acknowledge our commitment to
              reliability, excellence, innovation, customer service, and
              sustainability. Our consistent efforts to distribute medications
              efficiently and responsibly have earned us our stake as a trusted
              and esteemed player in the field of pharmaceutical distribution.
            </VendorContainer>
          </VendorContainers>
          <Grid
            item
            xs={6}
            style={{ padding: "10px 0px 10px 0px", marginTop: "-35%" }}
          >
            <Container>
              <img src={Award} />
              <br />
              <Topic>
                Dominant player in Institutional Business (2015-2016)
              </Topic>
              <SubTopic>Emcure Pharmaceuticals Limited </SubTopic>
            </Container>
            <Container>
              <img src={Award} />
              <Topic>Trusted Partner Award (SAHYOG)(2023)</Topic>
              <SubTopic>Dr. Reddy`s</SubTopic>
            </Container>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ padding: "10px 0px 10px 0px", marginTop: "-35%" }}
          >
            <Container>
              <img src={Award} />
              <Topic>Highest Revenue Achiever (2023) </Topic>
              <SubTopic>Dr. Reddy`s</SubTopic>
            </Container>
            <Container>
              <img src={Award} />
              <br />
              <Topic>Excellence in Pharmaceuticals Award (2022)</Topic>
              <SubTopic>Healthcare Logistics Association</SubTopic>
            </Container>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{}}>
          <Grid item xs={3} style={{ padding: "120px 0px 0px 20px" }}>
            <Container>
              <img src={Award} />
              <br />
              <Topic>
                Dominant player in Institutional Business (2015-2016)
              </Topic>
              <SubTopic>Emcure Pharmaceuticals Limited </SubTopic>
            </Container>
            <Container>
              <img src={Award} />
              <Topic>Trusted Partner Award (SAHYOG)(2023)</Topic>
              <SubTopic>Dr. Reddy`s</SubTopic>
            </Container>
          </Grid>
          <Grid item xs={3} style={{ padding: "120px 0px 0px 0px" }}>
            <Container>
              <img src={Award} />
              <Topic>Highest Revenue Achiever (2023) </Topic>
              <SubTopic>Dr. Reddy`s</SubTopic>
            </Container>
            <Container>
              <img src={Award} />
              <br />
              <Topic>Excellence in Pharmaceuticals Award (2022)</Topic>
              <SubTopic>Healthcare Logistics Association</SubTopic>
            </Container>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: "20px", height: "650px" }}>
            <CircularContainer />
            <VendorContainers>
              <VendorContainerHeading>
                Awards and Recognitions
              </VendorContainerHeading>
              <VendorContainer>
                PDPL has garnered numerous prestigious awards and recognitions
                for its exceptional performance in the field of pharmaceutical
                logistics. These accolades acknowledge the company's commitment
                to reliability, excellence, innovation, customer service, and
                sustainability. The company's consistent efforts to deliver
                medications efficiently and responsibly have earned it a
                reputation as a trusted and esteemed player in the field of
                pharmaceutical logistics.
              </VendorContainer>
            </VendorContainers>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Awards;
