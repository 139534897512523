import { Grid } from "@mui/material";
import React from "react";
import { styled } from "styled-components";
import service from "../../Assets/Home/Service/6859166 1 (2).svg";
import isMobile from "../../Assets/isMobile";
import { useNavigate } from "react-router-dom";

function Service() {
  const navigate = useNavigate();
  const Container = styled.div`
    padding: 5% 5%;
    background-color: #1d79bb;
    height: 100%;
    @media (max-width: 768px) {
      padding: 5% 5% 20px 5%;
    }
  `;
  const ContainerHeading = styled.div`
    font-family: Playfair Display;
    font-size: 42px;
    font-weight: 700;
    // line-height: 78px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #ffffff;
    margin-top: 7%;
    @media (max-width: 768px) {
      font-family: Playfair Display;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.03em;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Playfair Display;
      font-size: 36px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: 0.03em;
      text-align: left;
      margin-top: 0%;
      width: 60%;
    }
  `;

  const ContainerSubHeading = styled.div`
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 400;
    // line-height: 52px;
    letter-spacing: 0.15007999539375305px;
    text-align: left;
    color: #ffffff;
    @media (max-width: 768px) {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.15007999539375305px;
      text-align: center;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.15007999539375305px;
      text-align: left;
    }
  `;

  const ContainerButton = styled.button`
    width: 228.4553680419922px;
    height: 63px;
    padding: 6px 26.725372314453125px 6px 36px;
    border-radius: 5px;
    box-shadow: 0px -6px 12px 0px #0568a473;
    box-shadow: 0px 4px 11px 0px #0568a499;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    // text-align: left;
    color: #1d79bb;
    border: 0;
    margin-top: 20px;
    margin-right: auto;
    border: 0px;
    cursor: pointer;
    display: block;
    @media (max-width: 768px) {
      width: fit-content;
      height: 33px;
      padding: 6px 17px 6px 17px;
      border-radius: 3px;
      margin-right: auto;
      margin-left: auto;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: left;
      display: none;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: fit-content;
      border-radius: 5px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      margin-top: 20px;
      margin-right: auto;
      display: none;
    }
  `;
  const ContainerButtons = styled.button`
    width: 228.4553680419922px;
    height: 63px;
    padding: 6px 26.725372314453125px 6px 36px;
    border-radius: 5px;
    box-shadow: 0px -6px 12px 0px #0568a473;
    box-shadow: 0px 4px 11px 0px #0568a499;
    font-family: Helvetica;
    font-size: 26px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.5px;
    // text-align: left;
    color: #1d79bb;
    border: 0;
    margin-top: 20px;
    margin-right: auto;
    border: 0px;
    cursor: pointer;
    display: none;
    @media (max-width: 768px) {
      width: fit-content;
      height: 33px;
      padding: 6px 17px 6px 17px;
      border-radius: 3px;
      margin-right: auto;
      margin-left: auto;
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: left;
      display: block;
      margin-top: -5%;
    }
    @media (min-width: 481px) and (max-width: 1300px) {
      width: fit-content;
      border-radius: 5px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      margin-top: 20px;
      margin-right: auto;
      display: block;
    }
  `;
  const ContainerImage = styled.img`
    width: 100%;
    height: 100%;
    padding-bottom: 5%;
  `;

  return (
    <Container>
      {isMobile() && (
        <ContainerHeading>
          We provide the best service to our clients and the vendors
        </ContainerHeading>
      )}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isMobile() && (
            <ContainerHeading>
              We provide the best service to our clients and the vendors
            </ContainerHeading>
          )}
          <ContainerSubHeading>
            With our extensive network and cutting-edge technology, we
            seamlessly collect, warehouse, and deliver medicines to hospitals
            and medical stores across India. We have exclusive partnerships with
            renowned hospitals like Park Group, Jaipur Golden Hospital, and
            Medeor Group, managing their pharmaceutical distribution. Our large
            warehousing setup in multiple locations enables us to cater to
            wholesale and retail requirements. As an exclusive distributor, we
            hold distribution rights for reputed pharmaceutical companies,
            ensuring access to high-quality products for healthcare
            institutions.
          </ContainerSubHeading>
          <ContainerButton
            onClick={() => {
              navigate("/aboutus");
            }}
          >
            Learn More
          </ContainerButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContainerImage src={service} />
        </Grid>
      </Grid>
      <ContainerButtons
        onClick={() => {
          navigate("/aboutus");
        }}
      >
        Learn More
      </ContainerButtons>
    </Container>
  );
}

export default Service;
