import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import PartnersList from "./PartnersList";
import isMobile from "../../Assets/isMobile";

const imagePerRow = 15;

function PartnersLists() {
  const [next, setNext] = React.useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  return (
    <div
      style={{ margin: isMobile() ? "70px 10% 70px 0%" : "70px 10% 70px 15%" }}
    >
      <Grid container>
        {PartnersList?.slice(0, next)?.map((partner) => {
          return (
            <Grid
              item
              sm={2.4}
              xs={4}
              style={{ width: "100%", padding: "19px" }}
            >
              <img src={partner} style={{ width: "120px", height: "120px" }} />
            </Grid>
          );
        })}
      </Grid>
      <a
        onClick={() => {
          handleMoreImage();
        }}
        key={"item"}
        style={{
          marginLeft: "41%",
          marginRight: "auto",
          textTransform: "none",
          fontSize: "20px",
          fontWeight: "400",
          display: next >= PartnersList.length ? "none" : "block",
          width: "fit-content",
          height: "100%",
          cursor: "pointer",
        }}
      >
        <span>+ See More</span>
      </a>
    </div>
  );
}

export default PartnersLists;
