import React from 'react';
import styled from 'styled-components';
import Leader from '../../Assets/images/Leader.png';
import { Grid } from '@mui/material';
import isMobile from '../../Assets/isMobile';

function Leaders() {
  const CircularContainer = styled.div`
    height: 600px;
    width: 440px;
    border-radius: 0 700px 700px 0px;
    background: #0fb6d1;
    margin-right: auto;
    @media (max-width: 768px) {
      width: 70%;
      margin-bottom: 20px;
    }
  `;
  const Topic = styled.div`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 114.5%;
    color: #23262f;
    // margin-left: 174px;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 768px) {
      margin-left: 6.5%;
    }
  `;
  const Container = styled.div`
    margin-top: -497px;
    margin-bottom: 220px;
    @media (max-width: 768px) {
      margin-top: -589px;
    }
  `;
  const SubContainer = styled.div`
    width: 90%;
    padding-left: 10%;
    display: flex;
  `;
  const Name = styled.p`
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 114.5%;
    color: #23262f;
    margin-bottom: -20px;
  `;
  const Designation = styled.p`
    height: 28px;
    font-family: '"Times New Roman", Times, serif !important';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #757575;
  `;
  const CircleContainer = styled.div`
    width: 131px;
    height: 131px;
    border-radius: 50%;
    background-color: #b2bebb;
    margin-left: 27.2%;
  `;

  return (
    <div style={{ marginTop: '50px', marginBottom: isMobile() ? '-42%' : '-43%' }}>
      <CircularContainer />
      <Container>
        <Topic>Meet the leaders</Topic>
        <Grid Container style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Grid
            item
            sm={3}
            xs={4}
            style={{ textAlign: 'center', marginLeft: '5%', width: isMobile() ? '43%' : '23%' }}
          >
            <CircleContainer
              src='Leader'
              style={{ height: isMobile() && '98px', width: isMobile() && '98px' }}
            />
            <Name>Anil Kumar</Name>
            <Designation>Managing Director</Designation>
          </Grid>
          <Grid
            item
            sm={3}
            xs={4}
            style={{ textAlign: 'center', width: isMobile() ? '43%' : '23%' }}
          >
            <CircleContainer
              src='Leader'
              style={{ height: isMobile() && '98px', width: isMobile() && '98px' }}
            />
            <Name>Ashwani Kumar Bhatia</Name>
            <Designation>Managing Director</Designation>
          </Grid>
          <Grid
            item
            sm={3}
            xs={4}
            style={{ textAlign: 'center', marginLeft: '5%', width: isMobile() ? '43%' : '20%' }}
          >
            <CircleContainer
              src='Leader'
              style={{ height: isMobile() && '98px', width: isMobile() && '98px' }}
            />
            <Name>Himanshu Chawla</Name>
            <Designation>Chief Procurement Officer</Designation>
          </Grid>
          <Grid
            item
            sm={3}
            xs={4}
            style={{ textAlign: 'center', width: isMobile() ? '43%' : '23%' }}
          >
            <CircleContainer
              src='Leader'
              style={{ height: isMobile() && '98px', width: isMobile() && '98px' }}
            />
            <Name>Raghav Bhatia</Name>
            <Designation>Chief Operating Officer</Designation>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Leaders;
