import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import { MenuItems } from "./NavbarStyles";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Header/logo.png.svg";
import Submenu from "./Submenu";
import { styled } from "styled-components";

const drawerWidth = 240;
const navItems = ["Home", "About us", "Partners", "Career", "Contact us"];
const ButtonsStyles = styled.button`
  width: Hug (164.19px);
  height: Hug (48px);
  padding: 12px 23.19000244140625px 12px 24px;
  border-radius: 4px;
  background-color: #1d79bb;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15007999539375305px;
  text-align: left;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  @media (min-width: 481px) and (max-width: 1300px) {
    width: Hug (99px);
    height: Hug (32px);
    padding: 4px 10px 4px 11px;
    border-radius: 4px;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15007999539375305px;
    text-align: left;
  }
`;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{ backgroundColor: "white", color: "black", padding: "0% 3.3%" }}
        elevation={0}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
              cursor: "pointer",
            }}
          >
            <img
              src={Logo}
              onClick={() => {
                navigate("/");
              }}
            />
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
            style={{ marginLeft: "auto", marginRight: "0px" }}
          >
            <Submenu />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) =>
              item == "Contact us" ? (
                <>
                  <ButtonsStyles
                    onClick={() => {
                      navigate("/contactus");
                    }}
                  >
                    Contact us
                  </ButtonsStyles>
                </>
              ) : (
                <MenuItems
                  key={item}
                  active={
                    location.pathname.split("/")[1] === item ||
                    (location.pathname.split("/")[1] === "aboutus" &&
                      item === "About us")
                  }
                  onClick={() => {
                    navigate(
                      item === "About us"
                        ? "aboutus"
                        : item === "Contact us"
                        ? "contactus"
                        : item
                    );
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  {item}
                </MenuItems>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
